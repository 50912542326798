@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Gloock&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@layer components {
  .mobileView {
    @apply max-md:bg-white !z-40 max-md:gap-5 max-md:fixed max-md:items-center max-md:justify-center max-md:flex-col max-md:top-0 max-md:w-full max-md:h-full duration-300;
  }
  .bottomline {
    @apply after:content-[url(./assets/images/svg/bottom-curveline.svg)] after:absolute max-md:after:hidden after:w-full after:h-[11px] after:left-0 after:top-8 relative;
  }
  .bottom-curveline {
    @apply after:content-[url(./assets/images/svg/bottom-curve-line.svg)] after:absolute max-lg:after:hidden after:w-full after:h-[9px] after:left-0 after:bottom-6 relative;
  }
  .elegant-palm-bottomline {
    @apply after:content-[url(./assets/images/svg/palms-bottom-line.svg)] after:absolute max-lg:after:hidden after:w-full after:h-[9px] after:left-0 after:bottom-6 relative;
  }
  .plam-curveline {
    @apply after:content-[url(./assets/images/elegant/svg/plam-curve.svg)] after:absolute max-lg:after:hidden after:!w-[120px] after:h-[9px] after:left-0 after:bottom-8 relative;
  }
  .visionary-bottom-line {
    @apply after:content-[url(./assets/images/svg/visionary-bottom-line.svg)] after:absolute max-lg:after:hidden after:w-full after:h-[9px] after:left-0 after:bottom-6 relative;
  }
  .yellow-bottomline {
    @apply after:content-[url(./assets/images/svg/about-yellow-bottomline.svg)] min-[1100px]:after:flex after:hidden after:absolute after:w-full after:h-[9px] after:left-[10px] after:top-[25px] relative;
  }
  .hero-bottomline {
    @apply after:content-[url(./assets/images/svg/hero-bootom-line.svg)] min-[1100px]:after:flex after:hidden after:absolute after:w-full after:h-[9px] after:left-0 after:top-[20px] relative;
  }
    .heroPalms-bottomline {
    @apply after:content-[url(./assets/images/elegant/svg/elegant-hero-bottomline.svg)] min-[1100px]:after:flex after:hidden after:absolute after:w-full after:h-[9px] after:left-[12px] after:top-[37px] relative;
  }
  .map-bottomline {
    @apply after:content-[url(./assets/images/elegant/svg/map-bottomline.svg)] after:absolute max-lg:after:hidden after:w-full after:h-[11px] after:left-0 after:top-[24px] relative;
  }
  .about-bottomline{
      @apply after:content-[url(./assets/images/svg/about-yellow-bottomline.svg)] min-[1100px]:after:flex after:hidden after:absolute after:w-full after:h-[9px] after:left-[10px] after:top-[15px] relative
  }
  .palmProjects-curveline {
    @apply after:content-[url(./assets/images/svg/bottom-curve-line.svg)] after:absolute max-lg:after:hidden after:w-full after:h-[9px] after:left-0 after:bottom-[22px] relative;
  }
}
::-webkit-scrollbar {
  display: none;
}
