* {
  scroll-behavior: smooth !important;
}

.gallerySwiper .swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

.slick-dots li.slick-active {
  background: #F4AA1F;
  width: 60px !important;
}

.slick-dots li button {
  content: unset !important;
  display: none;
}

.slick-dots li {
  content: unset;
  width: 24px;
  height: 12px !important;
  background: white;
  border: 1px solid #F4AA1F;
  margin: 7px;
  border-radius: 21px;
  transition: all 300ms linear;
}

li::marker {
  font-size: 0 !important;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  margin-top: 18px !important;
  margin-bottom: 0 !important;
  top: 105%;
}

.slick-next:before {
  display: none;
}

.slick-prev:before {
  display: none;
}

.slick-dots li button:before {
  display: none;
}

.slick-slide {
  display: flex !important;
  justify-content: center !important;
}



@media (min-width: 1024px) {
  .gallerySwiper .swiper-slide-next img {
    height: 412px !important;
  }
  .gallerySwiper .swiper-slide-next video {
    height: 412px !important;
  }
}



.video-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.video-popup-content {
  background-color: #fff; 
  border: 2px solid white; 
  border-radius: 8px; 
  padding: 10px; 
  position: relative; 
}

.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: none;
  border: none;
  font-size: 20px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: black; 
}